

.popin_open
	overflow hidden
	
.mobile.popin_open, .mobile.popin_open body
	overflow hidden
	width 100%
	height 100%

#popin
	position fixed
	z-index 100
	top 0
	left 0
	right 0
	bottom 0
	background-color $grey
	opacity 0
	.wrapper
		overflow auto
		position absolute
		top 0
		left 0
		padding 40px
		height 100%
		width 100%
		-webkit-overflow-scrolling touch
	.close
		z-index 1
		position fixed
		top 20px
		right 20px
		&:before
			content ''
			display block
			margin 0 auto 5px
			background url($url-img'close.svg') no-repeat 50% 100%
			background-size contain
			width 16px
			height 16px
		&:hover
			text-decoration none
	

#slider
	width 100%
	height 100%
	overflow hidden
	position relative
	.panels
		flex-wrap nowrap
	.panels, .panel
		height 100%
	.panel
		align-items center
		position relative
	.content
		text-align center
		ul
			margin-bottom 40px
		li
			font-size 16px
			margin-bottom 20px
			text-align center
		li:last-child
			margin-bottom 0
	.media
		text-align right
		overflow hidden
		max-height 100%
	.media + .media
		text-align left
		img
			max-width 100%
			max-height 100%
			display inline-block
	nav > a
		position absolute
		top 50%
		color #fff
		transform translate(0,-50%)
		background url($url-img'down.svg') no-repeat 50% 100%
		background-size contain
		width 40px
		height 40px
		display block
	.prev
		left 0
		transform rotate(90deg)
	.next
		right 0
		transform rotate(-90deg)
	.prev.off, .next.off
		opacity 0

.name-talent-popin
	position absolute
	bottom 20px
	left 20%
	text-transform uppercase
	font-size 1.2rem
	display none


+max-screen($smartphone)
	#slider
		.panels
			flex-direction row
		.panel
			display flex
			flex-direction row
		.media + .content
			padding-top 20px
		.content ul
			margin-bottom 20px
		.content li
			font-size 12px
			margin-bottom 10px