*, *:before, *:after
	box-sizing border-box

body
	background $grey
	color $black
	font-size 14px
	font-family $main-font
	font-weight 400
	text-rendering optimizelegibility
	-webkit-font-smoothing antialiased
	-moz-osx-font-smoothing grayscale
	margin 0
	padding 0

figure
	padding 0
	margin 0
	
picture 
	display block


/* selection */
::-moz-selection
	background: #ccc
	text-shadow: none
	color: #666

::selection 
	background:  #ccc
	text-shadow: none
	color: #666


/* text */
h1, h2, h3, h4, h5
	margin 0 0 20px
	font-weight 400
	line-height 1em

h1, h2
	text-transform uppercase
	text-align center
	font-size 30px
h3
	font-size 24px

h5
	font-size 12px

a
	color $black
	text-decoration none
	&:focus
		outline 0
	&:hover, &:focus
		text-decoration underline
	
ul, ol
	list-style none
	margin 0 0 20px
	padding 0

p
	margin 0 0 30px

p, li, dt, dd, td, th, figcaption
	font-size 14px
	line-height 1.3em

strong, b
	font-weight 900

em, i
	font-style italic

dt
	font-weight 700
dd
	margin 0 0 20px 0

iframe
	display block
	max-width 100%
	margin-bottom 10px




.unvisible
	display none
	


// PICTOS
.information, .facebook, .twitter, .pinterest, .instagram, .search, .mail
	display inline-block
	vertical-align middle
	background-repeat no-repeat
	background-position 50% 50%
	background-size contain
	text-indent -9999px
	width 21px
	height 21px
.information, .facebook, .twitter, .pinterest, .instagram, a.search, .mail
	transition opacity .5s
	&:hover
		opacity .6
.information
	background-image url($url-img'information.svg')
	border-radius 50%
	background-color $black
	background-size 65% auto
.facebook
	background-image url($url-img'facebook.svg')
	background-position 0 -1px
.twitter
	background-image url($url-img'twitter.svg')
.pinterest
	background-image url($url-img'pinterest.svg')
.instagram
	background-image url($url-img'instagram.svg')
.search
	background-image url($url-img'search.svg')
.mail
	background-image url($url-img'mail.svg')
	border-radius 50%
	background-color $black
	background-size 65% auto
	
.setcard
    background-image url($url-img'print.svg')
    display inline-clock
    height 10px
    width 10px


// LOADING
.loading-elm
	background none
	width 200px
	height 200px
	transform translate(-50%,0) scale(0.2)
	opacity 0
	top 0
	left 50%
	position absolute
	transition opacity .5s $trans4
.loading-elm > div
	top 80px
	left 90px
	width 20px
	height 40px
	background #000
	border-radius 10px
	position absolute
	animation anim 1s linear infinite
	&:nth-of-type(1)
		transform rotate(0deg) translate(0,-60px)
		animation-delay -0.5s
	&:nth-of-type(2)
		transform rotate(36deg) translate(0,-60px)
		animation-delay -0.4s
	&:nth-of-type(3)
		transform rotate(72deg) translate(0,-60px)
		animation-delay -0.3s
	&:nth-of-type(4)
		transform rotate(108deg) translate(0,-60px)
		animation-delay -0.2s
	&:nth-of-type(5)
		transform rotate(144deg) translate(0,-60px)
		animation-delay -0.1s
	&:nth-of-type(6)
		transform rotate(180deg) translate(0,-60px)
		animation-delay 0s
	&:nth-of-type(7)
		transform rotate(216deg) translate(0,-60px)
		animation-delay 0.1s
	&:nth-of-type(8)
		transform rotate(252deg) translate(0,-60px)
		animation-delay 0.2s
	&:nth-of-type(9)
		transform rotate(288deg) translate(0,-60px)
		animation-delay 0.3s
	&:nth-of-type(10)
		transform rotate(324deg) translate(0,-60px)
		animation-delay: 0.4s
.loading .loading-elm
	opacity 1
@keyframes anim
	0%   { opacity: 1 } 
	100% { opacity: 0 }


// TABLET
+max-screen($tablet)
	p
		margin-bottom 20px


// SMARTPHONE
+max-screen($smartphone)
	.container
		padding 20px
	h1, h2
		font-size 20px
	body, p, li, dt, dd, td, th, figcaption
		font-size 12px