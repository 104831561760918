body.contact
	#main
		text-align center
		iframe
			margin-bottom 20px


#menu_article
	a
		position absolute
		top 35px
	.prev:before, .next:after
		content ''
		background url($url-img'down.svg') no-repeat 50% 100%
		background-size 22px
		width 22px
		height 26px
		display inline-block
		vertical-align middle
	.prev
		left 0
		&:before
			transform rotate(90deg)
	.next
		right 0
		&:after
			transform rotate(-90deg)
					
.share
	text-align center
	margin-bottom 20px
	p
		text-transform uppercase
		margin-bottom 20px
	a
		margin 0 3px

.chapo
	text-align center
	margin-top 20px
	padding 0 20px
	

.upload
	padding-left 10px
	.grid
		flex-wrap wrap
		.item
			width calc(50% - 20px)
			position relative
		.content
			opacity 1
			background-color rgba(0,0,0,.5)
			top auto
			color #fff
			padding 10px
			p
				margin-bottom 10px
				font-size 12px
			p:last-child
				margin-bottom 0
		input
			display block
			opacity 0
			position absolute
			top 0
			left 0
			height 100%
			width 100%
			cursor pointer

.text-center
	text-align center

+max-screen($smartphone)
	#menu_article a
		top 10px
	.blog .container
		padding-top 50px
	.blog .content img
		max-width 100%
		height auto
		display block
		margin-bottom 20px