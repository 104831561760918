form
	width 100%

fieldset
	border 0
	padding 0
	margin 0

input, textarea, select, button
	appearance none
	font-family $main-font
	font-size 14px
	border-radius 0
	border 1px solid $black
	background transparent
	transition border .3s, background .3s, color .3s, box-shadow .3s
	
input[type=text],input[type=url],input[type=email],input[type=password],input[type=number],input[type=tel]
	border 0
	border-bottom 1px solid $black
	padding 5px 10px
	width 100%
button
	width 100%
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button
	appearance none 
input[type=number]
	-moz-appearance textfield
placeholder( $black )

input:hover, textarea:hover, select:hover,
input:focus, textarea:focus, select:focus
	outline 0
	box-shadow inset  0 -10px 10px -10px #999

.file input
	cursor pointer
.file input:focus, .file input:hover
	box-shadow initial

textarea:focus, textarea:hover, select:focus, select:hover
	box-shadow inset 0 0 10px #999

textarea
	padding 10px
	min-height 150px
	width 100%

select
	padding 10px
	width 100%


/* DISABLED */
.disabled
	pointer-events none
	cursor default
	display none
input:disabled, button:disabled
	opacity .4



/* INPUT TEXT */
.radio, .checkbox, .text, .select, .file, .choices-container
	position relative
	margin-bottom 20px
	width 100%
.text, .select, .file
	label
		display none
.radio, .checkbox, .choices-container
	input
		height 50px
	label
		color $black
		font-weight 500
		padding-right 20px
		a
			color $black
			
.selects, .radios
	label
		display none

.radio, .checkbox, .choices-container
	label
		font-size 14px
	
	
.select
	select
		line-height 1em
		cursor pointer
	&:after
		content ""
		background url($url-img'down.svg') no-repeat 50% 50%
		background-size contain
		position absolute
		z-index -1
		right 10px
		top 0
		height 100%
		width 20px

.file
	input
		border 0
		
.selects
	.row
		margin-bottom 0
	.select
		width 30%
		

/* Radio / Chackbox */
.radio, .checkbox, .choices-container
	input
		position absolute
		left -9999px
	label
		cursor pointer
		display inline-block
		position relative
		transition color .3s
		padding-left 25px
		padding-top 3px
		&:before, &:after
			content ''
		&:before
			position absolute
			top 3px
			left 0
			border 1px solid $black
			width 16px
			height 16px
			transition border .2s
		&:after
			background-color transparent
			position absolute
			width 10px
			height 10px
			left 3px
			top 6px
			transform scale(0)
			transition background .2s, transform .2s
	input:checked+label
		&:after
			transform scale(1)
			background-color $black
	input:hover+label, input:focus+label
		&:before
			box-shadow inset 0 0 3px #999
	a
		text-decoration underline
		
.radio, .choices-container
	label
		&:before, &:after
			border-radius 50%
	
.radios, .checkboxes
	.radio, .choices-container
		display inline-block
		margin-right 20px
		width auto

.checkboxes, .radios, .selects
	.label
		margin-bottom 10px

.submit
	text-align right
	input, button
		padding 10px 20px
		font-family $alt-font
		&:hover, &:focus
			background-color $black
			color $grey
			box-shadow none

.choices-container
	input[type=radio]
		display: block
	label
		display: block
		width: 130px
		float: left