#header
	background #fff
	> div
		padding 5px 20px 20px
	.logo
		display block
		margin 0 auto 20px
		background url($url-img'logo.png') no-repeat
		background-size contain
		width 128px	
		height 80px
		text-indent -9999px
	.search 
		position relative
		&:after
			content ''
			width 1px
			height 80%
			background $black
			position absolute
			right -10px
			top 10%
	.facebook
		margin-left 10px
	.burger
		display none

#menu_left
	position absolute
	left 20px
	top 20px
	
#menu_right
	position absolute
	right 20px
	top 20px
	
#menu
	text-align center
	ol
		margin-bottom 0
	li
		display inline-block
		font-family $alt-font
		font-size 12px
		letter-spacing -.5px
		margin 0 5px
	.current
		a
			color #999

#search_form
	position absolute
	width 100%
	padding 20px
	background-color $grey
	border-bottom 1px solid #dddad3
	justify-content center
	display flex
	transform translate(0,-70px)
	transition transform .5s $trans4
	> div
		margin-bottom 0
	.text
		width 60%
	.submit
		position relative
		margin-left -40px
	.search
		border 0
		&:after
			display none
		&:hover, &:focus
			background-color transparent
.search_open
	#search_form
		transform translate(0,0)




+max-screen($smartphone)
	#search_form
		z-index 1
		.text
			width 100%
	#header .burger
		display inline-block
		vertical-align middle
		height 16px
		width 21px
		position relative
		z-index 101
		margin-right 10px
		i
			height 2px
			width 100%
			position absolute
			top 50%
			margin-top -1px
			background-color #000
		&:before, &:after
			content ''
			background-color #000
			width 100%
			height 2px
			position absolute
		&:before
			top 0
		&:after
			bottom 0
	#header .logo
		margin-bottom 0
	#header > div
		padding-bottom 5px
	#menu
		position fixed
		z-index 100000
		top 0
		bottom 0
		left 0
		right 0
		background #fff url($url-img'logo.png') no-repeat 50% 5px
		background-size 128px auto
		padding-top 100px
		display none
		li
			display block
			margin 10px 0
			font-size 16px
	.menu_open, .menu_open body
		overflow hidden
		height 100%
	.menu_open #menu
		display block