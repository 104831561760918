#main
	border-top 1px solid #dddad3
	
.container
	position relative
	margin auto
	padding 30px
	max-width $desktop
.row
	flex()


// MENU ALPHA
#menu_alpha
	text-align center
	padding 20px
	ol
		margin 0
	li
		display inline-block
		margin 0 10px
		color #999
	.current
		text-decoration underline
.talent-filter-star img
		width 15px


// GRID
.grid
	flex-row()
	max-width 1600px
	margin auto
	.item
		width 268px
		display block
		position relative
		margin 0 10px 20px
		height 400px
		//grey-filter(100%)
		//transition filter .15s linear
		//&:hover
			//grey-filter(0%)
		p
			font-size 18px
			text-transform uppercase
	.media
		img
			display block
			width 100%
			height 400px
			visibility hidden
		.name
			position absolute
			bottom 30px
			text-align center
			width 100%
			color #fff
			margin 0
		.name-board
			width 100%
			position absolute
			bottom 15px
			font-size 13px
			color #fff
			text-align center
			margin-bottom 0
	.content
		opacity 0
		background-color #fff
		padding 20px
		border 1px solid $black
		position absolute
		top 0
		left 0
		right 0
		bottom 0
		align-items center
		display flex
		flex-direction column
		text-align center
		justify-content center
		transition opacity .3s $trans4 
		ul:last-of-type
			margin-bottom 0
	a.item
		img
			grey-filter(100%)
			transition filter 1s $trans4
		&:hover img
			grey-filter(0%)

// GRID FLUID
.fluid
	.item		
		width 268px
		opacity 0
		transform translate(0,20px)
		.content
			opacity 1
			background-color transparent
			border 0
			position static
			display block
			transition none
			h2
				font-size 24px
			p
				font-size 12px
				margin 0
				font-family $alt-font
				letter-spacing -.5px
		.media
			transition transform .5s $trans4
			img
				grey-filter(0%)
		&:hover, &:focus
			text-decoration none
			.media
				transform scale(.95)
			

+max-screen($tablet)
	#menu_alpha li
		margin 0 5px
	.grid .item
		width 268px



+max-screen($smartphone)
	.container
		padding 20px
	.row
		flex-direction column
	.grid .item
		width calc(100% - 20px)
	.grid .content
		padding 10px
	.fluid .item .content h2
		font-size 16px


.board-host
	.back
		background white
		border 1px solid #000
		a
			display block
			height 100%
			&:hover
				text-decoration none
	.name-talent
		text-align center
		padding-top 90px
	ul
		text-align center
		li
			margin-bottom 10px

/* About us page */

.about-title
	margin-top 30px
	margin-bottom 0

.container-text-img-about
	display flex
	width 1200px
	margin auto
	padding-top 30px
	.columns-image-about
		width 39%
		img
			max-width 100%
		figcaption
			text-align center
			margin .5rem 0 1.5rem
			font-size 0.9rem
			width: 436px
	.content-text-about
		width 61%
		h2
			text-align left
			font-size 1.1rem
		p
			margin-bottom 1.2rem
			font-size 1rem

+max-screen($desktop-large)
	.container-text-img-about
		max-width $desktop
		.columns-image-about, .content-text-about
			width 50%

+max-screen($desktop)
	.container-text-img-about
		max-width 100%
		.content-text-about
			padding-left 2%

+max-screen($smartphone)
	.container-text-img-about
		display block
		.columns-image-about, .content-text-about
			width 100%
	.panel
		.media
			max-width 50%
			float left
			img
				max-width 100%
	#popin .wrapper
		padding 10px!important

.star-talent
	position absolute
	top 10px
	left 20px
	width 20px!important
	height 20px!important
	visibility visible!important

.grid-search
	margin-top 30px

@media screen and (max-width: $smartphone){
    .flipper{
    width: 100%;
    .front{
        background-size: cover;
    }
        .back{
        display: none;
        }
    }
    #slider nav>a{
        top: 92%!important;
        z-index: 1000!important;
    }
    .name-talent-popin{
        width: calc(100% - 80px)!important;
        text-align: center!important;
        left: 40px!important;
        bottom: 22px;
    }

}

@media screen and (max-width: $smartphone) and (orientation : landscape) {
    .panel .media img{
    width: 78%
    }
    .front{
        background-position: center;
        background-repeat: no-repeat;
    }
    #slider nav>a{
            top: 85%!important;
        }
        #menu_left{
        top: 35px;
        }
}

@media screen and (min-width: $smartphone){
    /* entire container, keeps perspective */
    .flip-container {
        perspective: 1000px;
    }
        /* flip the pane when hovered */
        .flip-container:hover .flipper, .flip-container.hover .flipper {
            transform: rotateY(180deg);
        }

    .flip-container, .front, .back {
        width: 100%;
        height: 400px;
    }

    /* flip speed goes here */
    .flipper {
        transition: 0.6s;
        transform-style: preserve-3d;

        position: relative;
    }

    /* hide back of pane during swap */
    .front, .back {
        backface-visibility: hidden;

        position: absolute;
        top: 0;
        left: 0;
    }

    /* front pane, placed above back */
    .front {
        z-index: 2;
        position relative;
        /* for firefox 31 */
        transform: rotateY(0deg);
        background-size cover
                background-repeat no-repeat
                background-position top center
    }
    .board-classic .front {
        grey-filter(100%);
    }
    /* back, initially hidden pane */
    .back {
        transform: rotateY(180deg);
        background-size cover
        background-repeat no-repeat
        background-position top center
    }
    .panel .media img{
        max-width: 550px;
    }
    #slider .media+.media img{
        max-width: 550px;
    }
}

.upload .grid .media img{
    visibility: visible;
}

