/*
 *  MIXINS
 */


grey-filter( $value )
    -webkit-filter grayscale($value)
    -moz-filter grayscale($value)
    -o-filter grayscale($value)
    -ms-filter grayscale($value)
    filter grayscale($value)
    //filter progid:DXImageTransform.Microsoft.BasicImage(grayscale=1)


placeholder( $color )
    input::-webkit-input-placeholder, textarea::-webkit-input-placeholder
        color $color
    input::-moz-input-placeholder, textarea::-moz-input-placeholder
        color $color
    input:-moz-input-placeholder, textarea:-moz-input-placeholder
        color $color
    input:-ms-input-placeholder, textarea:-ms-input-placeholder
        color $color
    

flex-row()
    display flex
    flex-direction row
    flex-wrap wrap

flex-col()
    display flex
    flex-direction column
    justify-content space-between

flex()
    display flex
    flex-direction row
    justify-content space-between
    > div, > form
        flex 1



/*======================================================================
    ORIENTATION
======================================================================*/
landscape(maxWidth)
    @media screen and (max-width : maxWidth) and (orientation : landscape)
        {block}

portrait(maxWidth)
    @media screen and (max-width : maxWidth) and (orientation : portrait)
        {block}



/*======================================================================
    SCREEN SIZE
======================================================================*/
screen(minWidth, maxWidth)
    @media screen and (min-width: minWidth) and (max-width: maxWidth)
        {block}

max-screen(maxWidth)
    @media screen and (max-width: maxWidth)
        {block}

min-screen(minWidth)
    @media screen and (min-width: minWidth)
        {block}

screen-height(minHeight, maxHeight)
    @media screen and (min-height: minHeight) and (max-height: maxHeight)
        {block}

max-screen-height(maxHeight)
    @media screen and (max-height: maxHeight)
        {block}

min-screen-height(minHeight)
    @media screen and (min-height: minHeight)
        {block}



/*======================================================================
    PIXEL DENSITY
======================================================================*/
hdpi(density = 1.3)
    media = " all"
    query = media + " and (-webkit-min-device-pixel-ratio: " + density + "), "
    query += media + " and (min--moz-device-pixel-ratio: " + density + "), "
    query += media + " and (-o-min-device-pixel-ratio: " + density + "/1), "
    query += media + " and (min-device-pixel-ratio: " + density + "), "
    query += media + " and (min-resolution: " + ceil( density * 96 ) + "dpi), "
    query += media + " and (min-resolution: " + density + "dppx)"
    @media query
        {block}


/*======================================================================
    FONT FACE
======================================================================*/
font-face($family, $file, $weight = 'normal')
    @font-face
        font-family $family
        font-weight $weight
        font-style normal
        src url($url-font + $file + '.eot')
        src url($url-font + $file + '.eot?#iefix') format('embedded-opentype'), url($url-font + $file + '.woff') format('woff'), url($url-font + $file + '.ttf') format('truetype'), url($url-font + $file + '.svg#' + $file) format('svg')

