body.home
	background-color $black
	position relative
	#header 
		> div
			padding 20px
		.logo
			display none
	.hero
		overflow hidden
		height calc(100vh - 59px)
		background-size cover
		background-repeat no-repeat
		background-position top center
		img
			width 100%
			height auto
	#footer
		position fixed
		bottom 0
		left 0
		width 100%
		color #fff
		a
			color #fff
	.container-logo-home
		position absolute
		height calc(100vh - 59px)
		top 59px
		display table
		width 100%
		text-align center
		.content-logo-home
			display table-cell
			vertical-align middle
			.logo-home
				max-width 100%



+max-screen($smartphone)
	body.home #header > div
		padding-top 50px
